<template>
    <div class="about">
        <a href="javascript:void(0);" class="scrollT" ref="about"></a>
        <div class="sub-banner" v-if="bannerList.length > 0" :style="{'backgroundImage':'url('+ bannerList[0].pic +')'}">
			<div class="container">
				<div class="main">
					<h2 class="wow fadeInDown">{{ bannerList[0].title }}</h2>
					<div class="txt wow fadeInUp" v-html="bannerList[0].sub_title"></div>
				</div>
			</div>
		</div>
        <div class="inside-nav wow fadeInDown">
			<div class="container">
				<div class="list">
					<a href="javascript:void(0);" @click="goAnchor(0)">01 <br/>Company</a>
					<a href="javascript:void(0);" @click="goAnchor(1)">02 <br/>Vision&Mission</a>
					<a href="javascript:void(0);" @click="goAnchor(2)">03 <br/>ESG</a>
					<a href="javascript:void(0);" @click="goAnchor(3)">04 <br/>Global presence</a>
				</div>
			</div>
		</div>
        <div class="mainbody">
            <div class="company-box cell-box">
                <a href="javascript:void(0);" class="anchor" ref="company"></a>
                <div class="container">
                    <div class="top">
                        <h3 class="wow fadeInLeft">Company</h3>
                        <div class="right wow fadeInRight">
                            <ul class="nav">
                                <li v-for="(cItem,index) in companyList"
                                    :class="cIndex === index ? 'active':''" 
                                    :key="cItem.id"
                                    @click="changeCompany(index)">
                                    {{ cItem.title }}
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="tabs">
                        <div class="item" v-if="companyList.length > 0">
                            <div class="main">
                                <div class="imgBg wow fadeInLeft" :style="{'backgroundImage':'url('+companyList[cIndex].pic+')'}"></div>
                                <div class="box wow fadeInRight">
                                    <div class="name">{{ companyList[cIndex].sub_title }}</div>
                                    <div class="txt" v-html=" companyList[cIndex].detail "></div>
                                </div>
                            </div>
                            <div class="num-list wow fadeInUp">
                                <div class="step" v-for="(cItem, index) in companyList[cIndex].dataList" :key="index">
                                    <div class="num"><span>{{cItem.value}}</span>{{cItem.units}}</div>
                                    <p>{{cItem.title}}</p>
                                </div>
                            
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="vision-box cell-box">
                <a href="javascript:void(0);" class="anchor" ref="vision"></a>
                <div class="container">
                    <h3 class="wow fadeInLeft">Vision&Mission</h3>
                    <div class="right">
                        <div class="info wow fadeInRight" v-html="missionInfo"></div>
                        <div class="pic wow fadeInRight" data-wow-delay="0.2s">
                            <img :src="missionPic" alt="">
                        </div>
                    </div>
                </div>
            </div>
            <div class="sustainable-box cell-box">
                <a href="javascript:void(0);" class="anchor" ref="sustainable"></a>
                <div class="container">
                    <h3 class="wow fadeInLeft">ESG</h3>
                    <div class="right wow fadeInRight">
                        <div class="tabs">
                            <div class="item" v-if="developList.length > 0">
                                <div class="imgBg" :style="{'backgroundImage':'url('+developList[dIndex].pic+')'}">
                                    <div class="main">
                                        <div class="name">{{ developList[dIndex].title }}</div>
                                        <div class="txt" v-html="developList[dIndex].detail">
                                            
                                        </div>
                                        <a v-if="developList[dIndex].files !== ''" :href="developList[dIndex].files" target="_blank" class="view">View report<i></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ul class="nav">
                            <li v-for="(dItem, index) in developList"
                                :key="dItem.id"
                                :class="dIndex === index ? 'active':''"
                                @click="changeManu(index)">
                                <div class="pic">
                                    <img src="../../assets/images/sustainable_icon.png" alt="">
                                </div>
                                <p>{{ dItem.title }}</p>
                            </li>
                            
                        </ul>
                    </div>
                </div>
            </div>
            <!-- <div class="global-box cell-box">
                <a href="javascript:void(0);" class="anchor" ref="global"></a>
                <div class="container">
                    <h3 class="wow fadeInDown">Global presence</h3>
                    <div class="pic wow fadeInUp">
                        <img src="../../assets/img/global.jpg" alt="">
                    </div>
                </div>
            </div> -->
            <div class="global-box cell-box" ref="map">
                <a href="javascript:void(0);" class="anchor" ref="global"></a>
                <div class="container">
                    <div class="title">Global presence</div>
                    <div :class="mActive ? 'main main-active':'main'">
                        <div class="line line1">
                            <img src="../../assets/images/line1.png" alt="">
                        </div>
                        <div class="line line2">
                            <img src="../../assets/images/line2.png" alt="">
                        </div>
                        <div class="line line3">
                            <img src="../../assets/images/line3.png" alt="">
                        </div>
                        <div class="line line4">
                            <img src="../../assets/images/line4.png" alt="">
                        </div>
                        <div class="line line5">
                            <img src="../../assets/images/line5.png" alt="">
                        </div>
                        <div class="line line6">
                            <img src="../../assets/images/line6.png" alt="">
                        </div>
                        <div class="line line7">
                            <img src="../../assets/images/line7.png" alt="">
                        </div>
                        <div class="logo">
                            <img src="../../assets/images/gb_logo.png" alt="">
                        </div>
                        <a href="javascript:void(0)" class="dot uk-dot">
                            <div class="box">
                                <div class="name">UK</div>
                                <p>Regional Office</p>
                            </div>
                            <div class="icon icon-yellow"></div>
                        </a>
                        <a href="javascript:void(0)" class="dot flex-dot france-dot">
                            <div class="box">
                                <div class="name">France</div>
                                <p>Regional Office & Warehouse</p>
                            </div>
                            <div class="icon icon-yellow"></div>
                        </a>
                        <a href="javascript:void(0)" class="dot spain-dot">
                            <div class="icon icon-yellow"></div>
                            <div class="box">
                                <div class="name">Spain</div>
                                <p>Regional Office & Warehouse</p>
                            </div>
                        </a>
                        <a href="javascript:void(0)" class="dot netherland-dot">
                            <div class="box">
                                <div class="name">Netherlands</div>
                                <p>Branch Company & Warehouse</p>
                            </div>
                            <div class="icon icon-yellow"></div>
                        </a>
                        <a href="javascript:void(0)" class="dot flex-dot germany-dot">
                            <div class="icon icon-yellow"></div>
                            <div class="box">
                                <div class="name">Germany</div>
                                <p>Branch Company</p>
                            </div>
                        </a>
                        <a href="javascript:void(0)" class="dot italy-dot">
                            <div class="icon icon-yellow"></div>
                            <div class="box">
                                <div class="name">Italy</div>
                                <p>Regional Office</p>
                            </div>
                        </a>
                        <a href="javascript:void(0)" class="dot japan-dot">
                            <div class="icon icon-yellow"></div>
                            <div class="box">
                                <div class="name">Japan</div>
                                <p>Branch Company</p>
                            </div>
                        </a>
                        <a href="javascript:void(0)" class="dot sihong-dot">
                            <div class="box">
                                <div class="name">Sihong</div>
                                <p>Manufacturing Base</p>
                            </div>
                            <div class="icon icon-green"></div>
                        </a>
                        <a href="javascript:void(0)" class="dot flex-dot qixian-dot">
                            <div class="icon icon-green"></div>
                            <div class="box">
                                <div class="name">Qixian</div>
                                <p>Manufacturing Base</p>
                            </div>
                        </a>
                        <a href="javascript:void(0)" class="dot flex-dot lyg-dot">
                            <div class="icon icon-green"></div>
                            <div class="box">
                                <div class="name">Lianyungang</div>
                                <p>Manufacturing Base</p>
                            </div>
                        </a>
                        <a href="javascript:void(0)" class="dot flex-dot yb-dot">
                            <div class="box">
                                <div class="name">Yibin</div>
                                <p>Manufacturing Base</p>
                            </div>
                            <div class="icon icon-green"></div>
                        </a>
                        <a href="javascript:void(0)" class="dot hd-dot">
                            <div class="icon icon-green"></div>
                            <div class="box">
                                <div class="name">Hengdian</div>
                                <p>Global & Manufacturing Base</p>
                            </div>
                        </a>
                        <a href="javascript:void(0)" class="dot flex-dot canada-dot">
                            <div class="icon icon-red"></div>
                            <div class="box">
                                <div class="name">Canada</div>
                                <p>Regional representative</p>
                            </div>
                        </a>
                        <a href="javascript:void(0)" class="dot flex-dot brazil-dot">
                            <div class="icon icon-red"></div>
                            <div class="box">
                                <div class="name">Brazil</div>
                                <p>Regional Office</p>
                            </div>
                        </a>
                        <a href="javascript:void(0)" class="dot flex-dot sa-dot">
                            <div class="icon icon-red"></div>
                            <div class="box">
                                <div class="name">South Africa</div>
                                <p>Regional representative</p>
                            </div>
                        </a>
                        <a href="javascript:void(0)" class="dot flex-dot india-dot">
                            <div class="box">
                                <div class="name">India</div>
                                <p>Regional representative</p>
                            </div>
                            <div class="icon icon-red"></div>
                        </a>
                        <a href="javascript:void(0)" class="dot flex-dot australia-dot">
                            <div class="icon icon-yellow"></div>
                            <div class="box">
                                <div class="name">Australia</div>
                                <p>Branch Company</p>
                            </div>
                        </a>
                        <a href="javascript:void(0)" class="dot flex-dot asia-dot">
                            <div class="box">
                                <div class="name">Southeast Asia </div>
                                <p>Manufacturing Base</p>
                            </div>
                            <div class="icon icon-green"></div>
                        </a>
                    </div>
                    <div class="m-pic">
                        <img src="../../assets/img/golbal_pic.jpg" alt="">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { getComapanyList, getMissionInfo, getDevelopList, getBannerList } from '../../request/api'
    import { WOW } from 'wowjs'
    export default {
        name:'About',
        metaInfo:{
            title:"DMEGC Solar-about",
            meta: [{                 // set meta
                name: 'keyWords',
                content: 'DMEGC Solar'
            },{
                name:'description',
                content:'DMEGC Solar'
            }]
        },
        data(){
            return {
                active:0,
                bannerList:[],
                companyList:[],
                cIndex:0,
                missionInfo:'',
                missionPic:'',
                developList:[],
                dIndex: 0,
                mActive: false,
                isMove: true
            }
        },
        mounted(){
            window.addEventListener('scroll', this.handleScroll)  
            this.$bus.$on('headNav',(data) => {
                this.goAnchor(data)
            })
            getBannerList({cate:'about'}).then( res => {

                console.log('about=',res.data)
                this.bannerList = res.data
            })

            getComapanyList().then( res => {

                this.companyList = res.data
                this.$nextTick(()=>{

                    const wow = new WOW({
                        animateClass: 'animated',
                        offset: 100
                    })
                    wow.init()
                })
            })

            getMissionInfo().then( res => {

                this.missionInfo = res.data.info.replace('|*|','<br/>')
                this.missionPic = res.data.pic
            })

            getDevelopList().then( res => {

                this.developList = res.data
                this.$nextTick(() => {
                    const wow = new WOW({
                        animateClass: 'animated',
                        offset: 100
                    })
                    wow.init();

                    if(this.$route.fullPath.indexOf('pos') !== -1) {

                        let num = parseInt(this.$route.fullPath.split('pos=')[1])
                        this.goAnchor(num)
                    } else {

                        this.$refs.about.scrollIntoView();
                    }
                })
            })
        },
        beforeDestroy() {
            this.$bus.$off('headNav')
            window.removeEventListener('scroll', this.handleScroll)
        },
        methods:{

            goAnchor( num ){

                if( num === 0 ) {

                    this.$refs.company.scrollIntoView({behavior: "smooth"});
                } else if( num === 1) {
                    
                    this.$refs.vision.scrollIntoView({behavior: "smooth"});
                }else if ( num === 2 ) {

                    this.$refs.sustainable.scrollIntoView({behavior: "smooth"});
                }else{

                    this.$refs.global.scrollIntoView({behavior: "smooth"});
                }   
            },
            changeCompany( num ) {

                this.cIndex = num
            },
            changeManu( num ) {

                this.dIndex = num
            },
            handleScroll(){

                let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
                let winH = document.documentElement.clientHeight || document.body.clientHeight
                
                if(scrollTop > (this.$refs.map.offsetTop - winH*0.5) && this.isMove) {
                    this.mActive = true
                    this.isMove = false
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
.about{
    .company-box{
        background: #F2F4F8;
        padding: 4.1667vw 0;
        .top{
            display: flex;
            justify-content: space-between;
        }
        .nav{
            display: flex;
            li{
                margin-right: 2.6042vw;
                font-size: 0.9896vw;
                line-height: 2.3438vw;
                color: rgba(0,0,0,0.6);
                cursor: pointer;
            }
            .active{
                border-bottom: 2px solid #E60011;
            }
        }
        .tabs{
            margin-top: 3.4375vw;
        }
        .main{
            display: flex;
        }
        .imgBg{
            width: 36.4583vw;
            height: 26.0417vw;
        }
        .box{
            width: 44.7917vw;
            height: 26.0417vw;
            box-sizing: border-box;
            padding: 3.6458vw 3.2083vw 0;
            background: #232323;
            .name{
                font-size: 1.5625vw;
                line-height: 1.9792vw;
                color: #fff;
                font-weight: bold;
            }
            .txt{
                margin-top: 1.3021vw;
            }
            /deep/p{
                font-size: 0.8333vw;
                line-height: 1.3542vw;
                color: #fff;
                font-weight: 300;
                margin-bottom: 0.8333vw;
                &:last-child{
                    margin-bottom: 0;
                }
            }

        }
        .num-list{
            margin-top: 4.375vw;
            p{
                color:rgba(0,0,0,0.6);
                max-width: 8.8542vw;
            }
        }
    }
    .vision-box{
        padding: 4.1667vw 0 3.125vw;
        .container{
            display: flex;
            justify-content: space-between;
        }
        .info{
            font-size: 1.6667vw;
            line-height: 1.9792vw;
            color: rgba(0,0,0,0.8);
        }
        .pic{
            margin-top: 2.1875vw;
        }
    }
    .sustainable-box{
        background: #F2F4F8;
        padding: 4.1667vw 0 3.9583vw;
        .container{
            display: flex;
            justify-content: space-between;
        }
        .right{
            display: flex;
            justify-content: space-between;
        }
        .tabs{
            width: 41.6667vw;
            .item{
                position: relative;
            }
            .imgBg{
                height: 37.5vw;
            }
            .main{
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                box-sizing: border-box;
                padding: 3.125vw;
                .name{
                    font-size: 1.6667vw;
                    line-height: 1.6667vw;
                    color: #fff;
                    font-weight: bold;
                }
                .txt{
                    margin-top: 0.8333vw;
                    font-size: 1.0417vw;
                    line-height: 1.4583vw;
                    color: #fff;
                }
                .view{
                    position: absolute;
                    left: 3.125vw;
                    bottom: 2.0833vw;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    box-sizing: border-box;
                    width: 13.5417vw;
                    height: 2.6042vw;
                    border:1px solid #fff;
                    padding: 0 1.0417vw 0 1.4583vw;
                    font-size: 0.8333vw;
                    color: #fff;
                    i{
                        display: block;
                        width: 1.25vw;
                        height: 1.25vw;
                        background: url('../../assets/images/arrowR.png') no-repeat;
                        background-size: cover;
                    }
                }
            }
        }
        .nav{
            width: 18.75vw;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            position: relative;
            z-index: 10;
            li{
                padding: 1.6667vw 0;
                border-bottom: 1px solid #C4C4C4;
                position: relative;
                cursor: pointer;
            }
            .pic{
                width: 2.8125vw;
                margin:0 0 0.625vw 2.0833vw;
                display: none;
            }
            .active{
                &::before{
                    content:'';
                    position: absolute;
                    width: 1.3021vw;
                    height: 1.1979vw;
                    background: url('../../assets/images/sustainable_arrow.png') no-repeat;
                    background-size: cover;
                    left: -1.3021vw;
                    top: 3.75vw;
                }
                .pic{
                    display: block;
                }
            }
            p{
                font-size: 1.1979vw;
                line-height: 1.6667vw;
                color: rgba(0,0,0,0.8);
                font-weight: bold;
                padding-left: 2.0833vw;
            }
        }
    }
    .global-box{
        position: relative;
        height: 44.8958vw;
        background: url(../../assets/img/global_bg.jpg) no-repeat center;
        background-size: cover;
        padding-top: 4.1667vw;
        box-sizing: border-box;
        .title{
            font-size: 1.25vw;
            line-height: 1.3333;
            color: #fff;
            font-weight: bold;
        }
        .main{
            margin-top: 8.3333vw;
            height: 30.2083vw;
            position: relative;
        }
        .logo{
            position: absolute;
            right: 12.6563vw;
            bottom: 15.8854vw;
            width: 4.5833vw;
            z-index: 5;
            transition: all .5s;
            opacity: 0;
            img{
                display: block;
                width: 100%;
            }
        }
        .line{
            position: absolute;
            z-index: 1;
            overflow: hidden;
            width: 0;
            animation-delay: 0.5s;
            animation-iteration-count: 1;
            animation-timing-function: linear;
            animation-fill-mode: forwards;
            img{
                position: absolute;
                right: 0;
                bottom: 0;
            }
        }
        .line1{
            //width:23.2292vw;
            height: 7.4479vw;
            right:15.2083vw;
            bottom: 17.3958vw;
            img{
                width:23.2292vw;
            }
            
        }
        .line2{
            //width: 21.25vw;
            height: 5.4688vw;
            right:16.1458vw;
            bottom: 14.2708vw;
            img{
                width: 21.25vw;
            }
        }
        .line3{
            //width: 8.75vw;
            height: 1.1458vw;
            right:15.8854vw;
            bottom: 15vw;
            img{
                width: 8.75vw;
            }
        }
        .line4{
            //width: 36.5625vw;
            height: 7.3958vw;
            right: 15.5208vw;
            bottom: 8.5417vw;
            img{
                width: 36.5625vw;
            }
        }
        .line5{
            //width: 55.9896vw;
            height: 9.7396vw;
            right: 15.5208vw;
            bottom: 6.25vw;
            img{
                width: 55.9896vw;
            }
        }
        .line6{
            width: 1.7708vw;
            //height: 11.5625vw;
            height: 0;
            right: 15.1042vw;
            top: 14.3229vw;
            img{
                width: 1.7708vw;
                bottom: auto;
                top: 0;
            }
        }

        .line7{
            width: 2.0833vw;
            //height: 3.5937vw;
            height: 0;
            right: 12.6563vw;
            top: 14.3229vw;
            img{
                width: 2.0833vw;
                bottom: auto;
                top: 0;
            }
        }

        .dot{
            display: block;
            position: absolute;
            z-index: 10;
            opacity: 0;
            transition: all .5s;
        }
        .uk-dot{
            top: 2.0833vw;
            right: 44.9479vw;
            text-align: right;
            .icon{
                margin: -0.5208vw 0 0 3.8542vw;
                position: relative;
                right: -1.875vw;
            }
        }
        .france-dot{
            right: 41.6667vw;
            top: 4.9479vw;
            text-align: right;
            .icon{
                margin-left: 0.9375vw;
            }
        }
        .spain-dot{
            top: 6.5625vw;
            right: 42.8125vw;
            text-align: right;
            .icon{
                margin: 0 0 0.2604vw 8.9583vw;
            }
        }
        .netherland-dot{
            top: 1.3542vw;
            left: 39.8958vw;
            .icon{
                margin-top: 1.25vw;
            }
        }
        .germany-dot{
            top: 3.9063vw;
            left: 41.4063vw;
            .icon{
                margin-right: 0.625vw;
            }
        }
        .italy-dot{
            top: 6.25vw;
            left: 41.4063vw;
            .icon{
                margin-bottom: 0.4167vw;
            }
        }
        .japan-dot{
            right: 11.1458vw;
            bottom: 8.5938vw;
            text-align: right;
            .icon{
                margin: 0 0 0.5208vw 4.5833vw;
            }
        }
        .hd-dot{
            right: 12.8417vw;
            bottom: 17.8167vw;
            .name{
                text-align: right;
            }
            .icon{
                margin-bottom: 0.625vw;
                display: none;
            }
        }
        .sihong-dot{
            right: 5.3125vw;
            bottom: 15.3646vw;
            .icon{
                margin-top: 0.625vw;
            }
        }
        .qixian-dot{
            right: 3.125vw;
            bottom: 15.1042vw;
            .icon{
                margin: 0.5208vw 0.2604vw 0 0;
            }
        }
        .lyg-dot{
            right: 2.3958vw;
            bottom: 12.9167vw;
            .icon{
                margin: -0.2vw 0.3208vw 0 0;
            }
        }
        .yb-dot{
            right: 11.25vw;
            bottom: 13.125vw;
            text-align: right;
            .icon{
                margin-left: 0.4167vw;
            }
        }
        .canada-dot{
            left: 8.1771vw;
            bottom: 13.1771vw;
            .icon{
                margin-right: 0.7813vw;
            }
        }
        .brazil-dot{
            left: 27.9167vw;
            bottom: 11.5625vw;
            .icon{
                margin-right: 0.7813vw;
            }
        }
        .sa-dot{
            left: 43.0729vw;
            bottom: 11.9792vw;
            .icon{
                margin-right: 0.7813vw;
            }
        }
        .india-dot{
            right: 24.7396vw;
            bottom: 15.7813vw;
            text-align: right;
            .icon{
                margin-left: 0.3125vw;
            }
        }
        .australia-dot{
            right: 6.875vw;
            bottom: 2.2917vw;
            .icon{
                margin-right: 0.7813vw;
            }
        }
        .asia-dot{
            right: 18.25vw;
            bottom: 9.725vw;
            text-align: right;
            .icon{
                margin-left: 0.4167vw;
            }
        }
        .flex-dot{
            display: flex;
        }
        .icon{
            width: 1.4583vw;
            height: 1.7708vw;
            background: url(../../assets/images/gb_icon1.png) no-repeat;
            background-size: cover;
            animation: boderM 2s infinite;
        }
        .icon-red{
            background-image: url(../../assets/images/gb_icon2.png);
        }
        .icon-green{
            width: 1.1458vw;
            height: 1.3542vw;
            background-image: url(../../assets/images/gb_icon3.png);
        }
        .name{
            font-size: 0.9375vw;
            line-height: 1;
            color: #fff;
            text-shadow: 0px 1px 2px #000;
        }
        p{
            font-size: 0.7292vw;
            line-height: 1;
            color: #fff;
            margin-top: 0.3208vw;
            text-shadow: 0px 1px 2px #000;
        }
        .main-active{
            .logo,
            .dot{
                opacity: 1;
            }
            .line1{
                animation-name: lineMove1;
                animation-duration: 1s;
            }
            .line2{
                animation-name: lineMove2;
                animation-duration: 1s;
            }
            .line3{
                animation-name: lineMove3;
                animation-duration: 0.75s;
            }
            .line4{
                animation-name: lineMove4;
                animation-duration: 1.75s;
            }
            .line5{
                animation-name: lineMove5;
                animation-duration: 2.5s;
            }
            .line6{
                animation-name: lineMove6;
                animation-duration: 0.75s;
            }
            .line7{
                animation-name: lineMove7;
                animation-duration: 0.5s;
            }
        }
        .m-pic{
            display: none;
        }
    }
    @keyframes boderM {
        0% {
            opacity:0
        }
        50% {
            opacity: 1;
        }
        100% {
            opacity:0
        }
    }
    @keyframes lineMove1 {
        0% {
            width: 0;
        }
        100% {
            width: 23.2292vw;
        }
    }
    @keyframes lineMove2 {
        0% {
            width: 0;
        }
        100% {
            width: 21.25vw;
        }
    }
    @keyframes lineMove3 {
        0% {
            width: 0;
        }
        100% {
            width: 8.75vw;
        }
    }
    @keyframes lineMove4 {
        0% {
            width: 0;
        }
        100% {
            width: 36.5625vw;
        }
    }
    @keyframes lineMove5 {
        0% {
            width: 0;
        }
        100% {
            width: 55.9896vw;
        }
    }
    @keyframes lineMove6 {
        0% {
            height: 0;
        }
        100% {
            height: 11.5625vw;
        }
    }
    @keyframes lineMove7 {
        0% {
            height: 0;
        }
        100% {
            height: 3.5937vw;
        }
    }
}

@media screen and (max-width: 750px) {
    .about{
        position: relative;
        .company-box{
            padding: .8rem 0;
            .top{
                display: block;
            }
            .nav{
                overflow-x: scroll;
                li{
                    font-size: .28rem;
                    line-height: .6rem;
                    margin-right: .4rem;
                    &:last-child{
                        margin-right: 0;
                    }
                }
            }
            .tabs{
                margin-top: .5rem;
            }
            .main{
                display: block;
            }
            .imgBg{
                width: 100%;
                height: 4.92rem;
            }
            .box{
                width: 100%;
                height: auto;
                padding: .6rem;
                .name{
                    font-size: .3rem;
                    line-height: .4rem;
                }
                .txt{
                    margin-top: .4rem;
                }
                /deep/p{
                    font-size: .24rem;
                    line-height: .34rem;
                    margin-bottom: .1rem;
                }
            }
            .num-list{
                margin-top: .4rem;
                .step{
                    p{
                        max-width: 1.8rem;
                    }
                }
            }
        }
        .vision-box{
            padding: .8rem 0;
            .container{
                display: block;
            }
            .info{
                font-size: .3rem;
                line-height: .5rem;
            }
            .pic{
                margin-top: .4rem;
            }
        }
        .sustainable-box{
            padding: .8rem 0;
            .container{
                display: block;
            }
            .right{
                flex-direction: column-reverse;
            }
            .nav{
                flex-direction: row;
                justify-content: flex-start;
                width: 100%;
                overflow-x: scroll;
                li{
                    padding: 0;
                    border-bottom: none;
                    flex: none;
                    width: auto;
                    margin-right: .4rem;
                }
                p{
                    font-size: .28rem;
                    line-height: .38rem;
                    padding: 0 0 .2rem;
                }
                .active{
                    .pic{
                        display: none;
                    }
                    border-bottom: 2px solid #E60011;
                }
            }

            .tabs{
                width: 100%;
                margin-top: .3rem;
                .imgBg{
                    height: 6.2rem;
                }
                .main{
                    padding: .6rem .4rem;
                    .name{
                        font-size: .32rem;
                        line-height: .42rem;
                    }
                    .txt{
                        margin-top: .4rem;
                        font-size: .26rem;
                        line-height: .36rem;
                    }
                    .view{
                        left: .4rem;
                        bottom: .4rem;
                        width: 2.6rem;
                        height: .6rem;
                        font-size: .28rem;
                        padding: 0 .2rem;
                        i{
                            width: .3rem;
                            height: .3rem;
                        }
                    }
                }
            }
        }
        .global-box{
            background: none;
            height: auto;
            padding: .8rem 0;
            .title{
                font-size: .32rem;
                color: rgba(0,0,0,0.8);
            }
            .main{
                display: none;
            }
            .m-pic{
                display: block;
                margin-top: .5rem;
                img{
                    display: block;
                    width: 100%;
                }
            }
        }
    }
}
</style>